import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonModule } from "@angular/material/button";
import { AppConfig, CORE_CONFIG, HeadersInterceptor, PipesModule, WtFrontSharedModule } from 'wt-front-shared';

import { FrontTemplateComponent } from './containers/front-template/front-template.component';
import { FrontAppRoutingModule } from '@app/front-app/src/lib/front-app-routing.module';
import { CountyComponent } from './containers/county/county.component';
import { HeaderBannerModule } from '@app/core/lib/components/header-banner/header-banner.module';
import { CoreModule } from '@app/core';
import { PageNotFoundComponent } from "@app/front-app/src/lib/components/page-not-found/page-not-found.component";
import { WtCommonModule } from "wt-common";


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,

    FrontAppRoutingModule,
    CoreModule.forRoot(),
    HeaderBannerModule,
    ToastrModule.forRoot(),
    PipesModule,

    WtFrontSharedModule,

  ],
  declarations: [
    FrontTemplateComponent,
    CountyComponent,
    PageNotFoundComponent],
  exports: [
    MatTabsModule,
    MatButtonModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true}
  ]
})
export class FrontAppModule {
  static forApp(appConfig: AppConfig): ModuleWithProviders<FrontAppModule> {
    return {
      ngModule: FrontAppModule,
      providers: [
        {provide: CORE_CONFIG, useValue: appConfig}
      ]
    };
  }
}
